/**
 * Copyright 2017 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React from "react";
import { Route, Redirect } from "react-router-dom";

class DefaultRoute extends React.Component {
  render() {
    const { isLoggedUser, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isLoggedUser)
            return (
              <Redirect
                exact
                to={{
                  pathname: "/app/directory",
                  state: { from: props.location }
                }}
              />
            );
          return null;
        }}
      />
    );
  }
}

export default DefaultRoute;
